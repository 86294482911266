import { useEffect, useState } from "react";
import MonthPickerInput from "react-month-picker-input";
require("react-month-picker-input/dist/react-month-picker-input.css");

export default function Filter(props) {
    const inputprop = { id: "month-input", className: "visually-hidden" }
    const currDate = new Date();
    const [filterMonth, setFilterMonth] = useState(currDate.getMonth());
    const [filterYear, setFilterYear] = useState(currDate.getFullYear());
    const [nextMonthAccessible, setNextMonthAccessible] = useState(false);
    const month_name = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    useEffect(() => {
        props.onDateChange(filterYear, filterMonth);
        if (
          filterMonth == currDate.getMonth() &&
          filterYear == currDate.getFullYear()
        ) {
          setNextMonthAccessible(()=>false)
        } else {
          setNextMonthAccessible(()=>true)
        }
    }, [filterMonth, filterYear])

  const handleDateChange = (maskedValue, selectedYear, selectedMonth) => {
    // console.log(maskedValue, selectedYear, selectedMonth);
    setFilterMonth(selectedMonth);
    setFilterYear(selectedYear);
  };

  const addmonth = () => {
    // console.log(filterMonth, currDate.getMonth());
    // console.log(filterYear, currDate.getFullYear());
    if (
      filterMonth == currDate.getMonth() &&
      filterYear == currDate.getFullYear()
    ) {
    } else {
      if (filterMonth == 11) {
        setFilterYear(filterYear + 1);
        setFilterMonth(0);
      } else setFilterMonth(filterMonth + 1);
    }
  };

    const submonth = () => {
        if (filterMonth == 0) {
            setFilterYear(filterYear - 1);
            setFilterMonth(11)
        } else setFilterMonth(filterMonth - 1)
    }

    return (
        <div className={props.display ? "khataFilter rounded-3 d-flex" : "khataFilter rounded-3 d-none"}>
            <div className='h-100 bg-danger d-flex ms-auto rounded-2'>
                <i className="bi bi-chevron-left m-auto btn btn-danger h-100 d-flex align-items-center" onClick={submonth}></i>
                <label htmlFor='month-input' className='bg-danger text-white h4 my-auto rounded'>{month_name[filterMonth] + `'` + filterYear}</label>
                <MonthPickerInput
                    year={filterYear}
                    month={filterMonth}
                    onChange={handleDateChange}
                    maxDate={[currDate.getMonth(), currDate.getFullYear()]}
                    title={month_name[filterMonth] + '/' + filterYear}
                    inputProps={inputprop}
                />
                <i className={nextMonthAccessible?"bi bi-chevron-right m-auto btn btn-danger h-100 d-flex align-items-center":"bi bi-chevron-right m-auto btn btn-danger h-100 d-flex align-items-center disabled"} onClick={addmonth}></i>
            </div>
        </div>
    )
}
