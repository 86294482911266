import React, { useState, useContext } from 'react';
import ModalImage from "react-modal-image";
import Parse from '../services/parse.service';
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd'

function Dummy(props){
    const steps = [
        {
            id: 'intro1',
            attachTo: { element: ".nav-link[href='/']", on: 'right'},
            beforeShowPromise: function () {
                return new Promise(function (resolve) {
                  setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                  }, 500);
                });
              },
              buttons: [
                {
                  classes: 'btn btn-light',
                  text: 'Exit',
                  type: 'cancel'
                },
                {
                  classes: 'btn btn-danger mx-3 my-3',
                  text: 'Next',
                  type: 'next'
                }
              ],
              classes: 'text-light bg-danger p-3',
              highlightClass: 'highlight',
              scrollTo: false,
              cancelIcon: {
                enabled: true,
              },
              title: 'Welcome to Bahi Khata',
              text: ['It is a simple app to manage your payabale/receivables and cashflows.'],
              when: {
                show: () => {
                  console.log('show step');
                },
                hide: () => {
                  console.log('hide step');
                }
              }
        },
        {
            id: 'intro2',
            attachTo: { element: ".nav-link[href='/cashbook']", on: 'right'},
            beforeShowPromise: function () {
                return new Promise(function (resolve) {
                  setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                  }, 500);
                });
              },
              buttons: [
                {
                  classes: 'btn btn-light',
                  text: 'Back',
                  type: 'back'
                },
                {
                  classes: 'btn btn-danger',
                  text: 'Next',
                  type: 'next'
                }
              ],
              classes: 'text-light bg-danger p-3',
              highlightClass: 'high',
              scrollTo: false,
              cancelIcon: {
                enabled: true,
              },
              title: 'Khatabook',
              text: ['It helps to manage payabale/receivables.'],
              when: {
                show: () => {
                  console.log('show step');
                },
                hide: () => {
                  console.log('hide step');
                }
              }
        },
        {
            id: 'intro3',
            attachTo: { element: ".nav-link[href='/passbook']", on: 'right'},
            beforeShowPromise: function () {
                return new Promise(function (resolve) {
                  setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                  }, 500);
                });
              },
              buttons: [
                {
                  classes: 'btn btn-light',
                  text: 'Back',
                  type: 'back'
                },
                {
                  classes: 'btn btn-danger',
                  text: 'Exit',
                  type: 'cancel'
                }
              ],
              classes: 'text-light bg-danger p-3',
              highlightClass: 'high',
              scrollTo: false,
              cancelIcon: {
                enabled: true,
              },
              title: 'Passbook',
              text: ['It helps to manage payabale/receivables.'],
              when: {
                show: () => {
                  console.log('show step');
                },
                hide: () => {
                  console.log('hide step');
                }
              }
        }
    ]

    const tourOptions = {
        defaultStepOptions: {
          cancelIcon: {
            enabled: true
          }
        },
        useModalOverlay: false
      };
    
      function MyButton() {
        const tour = useContext(ShepherdTourContext);
      
        return (
          <button className="button dark" onClick={tour.start}>
            Start Tour
          </button>
        );
      }
    
    return (
        <div className="container">
            <ShepherdTour steps={steps} tourOptions={tourOptions} >

                <MyButton />
            </ShepherdTour>
        </div>
    )
}

export default Dummy;