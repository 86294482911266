import React, { useEffect, useState } from "react";
import { Button, Container, Row, Col, Image, ListGroup } from 'react-bootstrap';
const accounting = require('accounting');
import moment from 'moment';
import ModalImage from "react-modal-image";

function Txn(props) {
  const { data } = props;

  const cancelledView = () => {
    return (
      <>
        <div className="col-3 px-0" align="right">
          <i className="text-decoration-line-through text-muted">
            {accounting.formatMoney(Math.abs(data.get("amount")), '₹', 0)}
          </i>
        </div>
        <div className="col-9">
          <div className="row">
            <div className="text-decoration-line-through text-muted col-8" align="left">
              {data.get("detail")}
            </div>
            <div className="text-decoration-line-through text-muted fst-italic col-4" align="right">
              {moment(data.get("date")).format('DD MMM')}
            </div>
          </div>
        </div>
      </>
    )
  }

  const normalView = () => {
    return (
      <div className="row">
        <div className="col-3 px-0" align="right">
          <i className="">
            {accounting.formatMoney(Math.abs(data.get("amount")), '₹', 0)}
          </i>
        </div>
        <div className="col-8">
          <div className="row">
            <div className="col-8" align="left">
              {data.get("detail")}
            </div>
            <div className="col-4" align="right">
              <b><i>{moment(data.get("date")).format('DD MMM')}</i></b>
            </div>

          </div>
          <div className="row">
            <div className="col-8">
              {data.get("attachment") ?
                <ModalImage
                  small={data.get("attachment")}
                  large={data.get("attachment")}
                  className="my-2 img-fluid rounded-2 border border-2 border-danger"
                />
                : null}
            </div>
          </div>
        </div>
        <div className="col-1">
          {data.id ? (
            <a className="text-black btn bg-not-transparent-on-hover w-auto ms-auto" type="button" data-bs-toggle="dropdown" aria-expanded="false" title="More options" align="right">
              <span className="bi bi-three-dots-vertical"></span>
            </a>
          ) : null}
          <ul className="dropdown-menu row w-auto">
            <li className="col-12">
              <button className="w-100 btn btn-outline-dark rounded" data-bs-toggle="offcanvas" data-bs-target="#editTxnDrawer" aria-controls="editTxnDrawer"
                onClick={() => { 
                  props.onEdit(data);
                  // console.log(data);
                   }}>
                <span className="bi bi-pencil-fill"> Edit</span>
              </button>
            </li>
            <li className="col-12 py-1">
              <button className="w-100 btn btn-danger rounded" onClick={() => props.onDel(data)} data-bs-toggle="modal" data-bs-target="#delModal">
                <i className="bi bi-trash"> Delete</i>
              </button>
            </li>
          </ul>
        </div>
      </div>
    )
  }

  return (
    <ListGroup.Item className="bg-transparent">
      <Row className="align-baseline">
        {data.get("cancelled") ? cancelledView() : normalView()}
      </Row>
    </ListGroup.Item>
  )
}

export default Txn;
