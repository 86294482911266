import React from "react";
import { api } from "services";
import { useDispatch, useSelector } from "react-redux";
// import { setForUpdate } from "redux/actions/updateAction";
import { getTransctions } from "redux/actions/transctionAction";
import { getReduxAccounts } from "redux/actions/accountActions";

function Modal(props) {
  const updateData = useSelector((state) => state.updater.allUpdates);
  const forDel = updateData ? updateData.delTxn : null;
  const forDelRc = updateData ? updateData.delRcTxn : null;
  const forDelAc = updateData ? updateData.delAcc : null;
  const allaccountsData = useSelector((state) => state.accounts.allAccounts);
  const copiedAccounts = allaccountsData ? [...allaccountsData] : null;
  const allTransction = useSelector((state) => state.transction.allTransctions);
  const copiedTransactions = allTransction ? [...allTransction] : null;
  const dispatch = useDispatch();

  const updateAccountState = (newAc) => {
    const arrayIndex = copiedAccounts.findIndex(obj => obj.id == newAc.id);
    copiedAccounts[arrayIndex] = newAc;
    dispatch(getReduxAccounts(copiedAccounts));
  }

  const refreshAccountState = (delAc) => {
    const newAccounts = copiedAccounts.filter(obj => obj.id !== delAc.id);
    dispatch(getReduxAccounts(newAccounts));
  }

  const updateTransactionsState = (newTxn) => {
    const arrayIndex = copiedTransactions.findIndex(obj => obj.id == newTxn.id);
    copiedTransactions[arrayIndex] = newTxn;
    dispatch(getTransctions(copiedTransactions));
  }


  const deleteit = (e) => {
    e.preventDefault();
    if (forDel) {
      const acId = forDel.get("accountId") ? forDel.get("accountId").id : null;
      api
        .deleteTransaction(forDel.id)
        .then((result) => {
          if (acId) {
            api.updateBalance(acId, -forDel.get("amount")).then((res) => {
              // triggers khatabook accounts data
              updateAccountState(res);
            })
          }
          // trigger txn data
          updateTransactionsState(result);
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (forDelRc) {
      console.log(forDelRc);
      api.DeleteRecursiveTxn(forDelRc).then((res)=>{
        console.log(res);
      }).catch((err)=>err)
    } else if (forDelAc) {
      api.deleteAccount(forDelAc).then((res)=>{
        refreshAccountState(forDelAc);
      }).catch((err)=>err)
    } 
    else {
      console.error("No transaction to delete!");
    }
  };


  return (
    <div className="modal fade" id="delModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-0">
            <h1 className="modal-title fs-5" id="exampleModalLabel">Are you sure ?</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          {/* <div className="modal-body">
            You can also edit it instead. This can't be undone.
          </div> */}
          <div className="modal-footer border-0">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"> Cancel</button>
            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={deleteit}>
              <i className="bi bi-trash-fill"></i> Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal;
