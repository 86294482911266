import { create } from 'zustand'
import { fetchUser } from '../services/auth.service'
import { produce } from 'immer'
import { api } from 'services'

const user = fetchUser()
// console.log(
//     'user',
//     user,
//     user && user.get('onboardingInfo') ? user.get('onboardingInfo') : {}
// )

function getOnboardingTaskStatus(id) {
    return user && user.get('onboardingInfo')
        ? user.get('onboardingInfo')[id]
        : null
}

function getShowOnboardingTasks() {
    const onboardModalInfo =
        user && user.get('businessName') ? user.get('businessName') : null
    // console.log('onboardModalInfo', onboardModalInfo, user)
    if (!onboardModalInfo) return false // user haven't completed the onboarding modal
    const onboardingInfo =
        user && user.get('onboardingInfo') ? user.get('onboardingInfo') : null
    if (!onboardingInfo) return true
    const onboardingIds = ['new-account', 'new-transaction', 'new-cashbook']
    return onboardingIds.some((id) => !onboardingInfo[id])
}

async function getAccountRoute() {
    const accounts = await api.getAllAccounts()
    const latestAccount = accounts.pop()
    // console.log('latestAccount', latestAccount)
    return `/khata/${latestAccount.id}`
}

export const useOnboardingStore = create((set, get) => ({
    onboardingInfo:
        user && user.get('onboardingInfo') ? user.get('onboardingInfo') : {},
    currentStep: 0,
    steps: [],
    run: false,
    currentTaskId: null,
    showOnboardingTasks: getShowOnboardingTasks(),
    tasks: [
        {
            id: 'new-account',
            title: 'Add your first account',
            description: 'Add your first account to get started',
            completed: getOnboardingTaskStatus('new-account'),
            route: '/',
            steps: [
                {
                    target: '.first-step',
                    content: 'KHATABOOK'
                },
                {
                    target: '.second-step',
                    content: 'New Account',
                    disableEnforceFocus: true,
                    hideFooter: true,
                    disableBeacon: true,
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    placement: 'top',
                    spotlightClicks: true
                },
                {
                    target: '.third-step',
                    content: "Account Holder's Name",
                    disableEnforceFocus: true,
                    hideFooter: true,
                    disableBeacon: true,
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    placement: 'bottom',
                    spotlightClicks: true
                },
                {
                    target: '.forth-step',
                    content: "Account Holder's Number",
                    disableEnforceFocus: true,
                    hideFooter: true,
                    disableBeacon: true,
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    placement: 'bottom',
                    spotlightClicks: true
                },
                {
                    target: '.fifth-step',
                    content: 'Submit',
                    disableEnforceFocus: true,
                    hideFooter: true,
                    disableBeacon: true,
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    placement: 'bottom',
                    spotlightClicks: true
                }
            ]
        },
        {
            id: 'new-transaction',
            title: 'Add your first transaction',
            completed: getOnboardingTaskStatus('new-transaction'),
            description: 'Add your first transaction to start using khatabook',
            route: () => getAccountRoute(),
            steps: [
                {
                    target: '.sixth-step',
                    content: 'New Transaction',
                    spotlightClicks: true,
                    hideFooter: true,
                    disableEnforceFocus: true,
                    disableBeacon: true,
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    placement: 'top'
                },
                {
                    target: '.seventh-step',
                    content: 'Add Amount',
                    hideFooter: true,
                    disableEnforceFocus: true,
                    disableBeacon: true,
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    placement: 'top',
                    spotlightClicks: true
                },
                {
                    target: '.eighth-step',
                    content: 'Add Transaction Details',
                    spotlightClicks: true,
                    hideFooter: true,
                    disableEnforceFocus: true,
                    disableBeacon: true,
                    disableOverlayClose: true,
                    hideCloseButton: true
                },
                {
                    target: '.send-sms-step',
                    content: 'Send SMS',
                    spotlightClicks: true,
                    hideFooter: true,
                    disableEnforceFocus: true,
                    disableBeacon: true,
                    disableOverlayClose: true,
                    hideCloseButton: true
                },
                {
                    target: '.ninth-step',
                    content: 'Submit',
                    spotlightClicks: true,
                    hideFooter: true,
                    disableEnforceFocus: true,
                    disableBeacon: true,
                    disableOverlayClose: true,
                    hideCloseButton: true
                }
            ]
        },
        {
            id: 'new-cashbook',
            completed: getOnboardingTaskStatus('new-cashbook'),
            title: 'Add your first transaction in cashbook',
            description:
                'Add your first transaction in cashbook to start managing your cash',
            route: '/cashbook',
            steps: [
                {
                    target: '.tenth-step',
                    content: 'New Transaction',
                    spotlightClicks: true,
                    hideFooter: true,
                    disableEnforceFocus: true,
                    disableBeacon: true,
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    placement: 'top'
                },
                {
                    target: '.seventh-step',
                    content: 'Add Amount',
                    hideFooter: true,
                    disableEnforceFocus: true,
                    disableBeacon: true,
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    placement: 'bottom',
                    spotlightClicks: true
                },
                {
                    target: '.select-user-step',
                    content: 'Select Account Holder',
                    hideFooter: true,
                    disableEnforceFocus: true,
                    disableBeacon: true,
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    placement: 'bottom',
                    spotlightClicks: true
                },
                {
                    target: '.eighth-step',
                    content: 'Add Transaction Details',
                    spotlightClicks: true,
                    hideFooter: true,
                    disableEnforceFocus: true,
                    disableBeacon: true,
                    disableOverlayClose: true,
                    hideCloseButton: true
                },
                {
                    target: '.send-sms-step',
                    content: 'Send SMS',
                    spotlightClicks: true,
                    hideFooter: true,
                    disableEnforceFocus: true,
                    disableBeacon: true,
                    disableOverlayClose: true,
                    hideCloseButton: true
                },
                {
                    target: '.ninth-step',
                    content: 'Submit',
                    spotlightClicks: true,
                    hideFooter: true,
                    disableEnforceFocus: true,
                    disableBeacon: true,
                    disableOverlayClose: true,
                    hideCloseButton: true
                }
            ]
        }
    ],
    goToNextStep: async () => {
        if (!get().currentTaskId || !get().run) return
        // console.log('goToNextStep', get().currentStep)
        await new Promise((r) => setTimeout(r, 300))
        set(
            produce((state) => {
                state.currentStep = state.currentStep + 1
            })
        )
    },
    startOnboardingTask: (id) => {
        const task = get().tasks.find((task) => task.id === id)
        if (!task) return
        set(
            produce((state) => {
                state.currentTaskId = id
                state.currentStep = 0
                state.steps = task.steps
                state.run = id === 'new-transaction' ? false : true
                state.showOnboardingTasks = false
            })
        )
    },
    writeOnboardingStore: (dataOrFn) => {
        if (typeof dataOrFn === 'function') {
            set(dataOrFn(get()))
        } else {
            set(dataOrFn)
        }
    }
}))
