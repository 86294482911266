import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { api } from 'services'
import { useNavigate } from 'react-router-dom'
import 'react-phone-number-input/style.css'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { useDispatch, useSelector } from 'react-redux'
import { getReduxAccounts } from 'redux/actions/accountActions'
import { useOnboardingStore } from 'store/onboarding-store'
import { debounce } from 'utils'

export default function NewAccount(props) {
    const [invalid, setInValid] = useState(true)
    const allaccountsData = useSelector((state) => state.accounts.allAccounts)
    const newAccounts = allaccountsData ? [...allaccountsData] : null
    const dispatch = useDispatch()
    const goToNextStep = useOnboardingStore((state) => state.goToNextStep)

    const goToNextStepDebounced = useMemo(
        () => debounce(goToNextStep, 3000),
        []
    )

    const [values, setValues] = useState({
        name: '',
        phone: '',
        loading: false,
        errormsg: '',
        successmsg: ''
    })
    let navigate = useNavigate()

    useEffect(() => {
        // console.log("+91" + phone + ":", isPossiblePhoneNumber("+91" + phone), isValidPhoneNumber("+91" + phone));
        let temp =
            isValidPhoneNumber('+91' + values.phone) || values.phone == ''
        setInValid(!temp)
    }, [values.phone])

    const add = (e) => {
        setValues({
            ...values,
            loading: true
        })
        e.preventDefault()
        api.getAccountByName(values.name).then((account) => {
            if (account) {
                setValues({
                    ...values,
                    loading: false,
                    errormsg: 'Account with this name exists already.',
                    successmsg: ''
                })
            } else {
                api.account(values.name, values.phone)
                    .then((result) => {
                        if (result) {
                            updateAccountsState(result)
                        }
                        // props.onTriggerAccounts();
                        setValues({
                            name: '',
                            phone: '',
                            errormsg: '',
                            loading: false,
                            successmsg: 'Account Created Successfully'
                        })
                        setTimeout(() => {
                            hideOffcanvas()
                            setValues({
                                name: '',
                                phone: '',
                                errormsg: '',
                                successmsg: '',
                                loading: false
                            })
                        }, 500)
                    })
                    .catch((error) => {
                        setValues({
                            ...values,
                            errormsg: error.message,
                            successmsg: '',
                            loading: false
                        })
                        console.error(error)
                    })
            }
        })
    }

    const updateAccountsState = (newAc) => {
        newAccounts.push(newAc)
        dispatch(getReduxAccounts(newAccounts))
    }

    const nameChangeHandler = (e) => {
        const target = e.target
        goToNextStepDebounced()
        setValues((values) => ({
            ...values,
            name: target.value,
            errormsg: '',
            successmsg: ''
        }))
    }

    const phoneChangeHandler = (e) => {
        const target = e.target
        goToNextStepDebounced()
        setValues((values) => ({
            ...values,
            phone: target.value,
            errormsg: '',
            successmsg: ''
        }))
    }

    const hideOffcanvas = () => {
        const myOffCanvas = document.getElementById('addContactDrawer')
        const openedCanvas = bootstrap.Offcanvas.getInstance(myOffCanvas)
        openedCanvas.hide()
    }

    return (
        <div
            className="offcanvas offcanvas-start text-danger"
            tabIndex="-1"
            id="addContactDrawer"
            aria-labelledby="offcanvasExampleLabel"
        >
            <div className="offcanvas-header bg-danger text-light">
                <h3 className="offcanvas-title">Add Account</h3>
                <button
                    type="button"
                    className="btn-close text-reset bg-light"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                ></button>
            </div>
            <div className="offcanvas-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <form
                                onSubmit={(e) => {
                                    add(e)
                                    goToNextStep()
                                }}
                                id="newaccountform"
                            >
                                <div className="py-3">
                                    <input
                                        type="text"
                                        className={`${
                                            values.name
                                                ? 'form-control is-valid'
                                                : 'form-control is-invalid'
                                        } third-step`}
                                        id="account"
                                        placeholder="Account Name"
                                        value={values.name}
                                        onChange={nameChangeHandler}
                                        required
                                    />
                                </div>
                                <div className="py-3">
                                    <input
                                        type="number"
                                        className={`${
                                            invalid
                                                ? 'form-control is-invalid'
                                                : values.phone
                                                ? 'form-control is-valid'
                                                : 'form-control'
                                        } forth-step`}
                                        id="phone"
                                        pattern="^\+?\d{10}"
                                        placeholder="Phone no. (Optional)"
                                        value={values.phone}
                                        onChange={phoneChangeHandler}
                                    />
                                </div>
                                {values.loading ? (
                                    <div className="form-group text-start">
                                        <div
                                            className="form-check-label alert alert-warning text-capitalized"
                                            role="alert"
                                        >
                                            <span className="spinner-border spinner-border-sm"></span>{' '}
                                            Loading...
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {values.errormsg ? (
                                    <div className="form-group text-start animate__animated animate__pulse">
                                        <div
                                            className="form-check-label alert alert-danger text-capitalized"
                                            id="newacerror"
                                        >
                                            <i className="bi bi-exclamation-circle-fill"></i>{' '}
                                            {values.errormsg}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="py-2"></div>
                                )}
                                {values.successmsg ? (
                                    <div className="form-group text-start">
                                        <div
                                            className="form-check-label alert alert-success text-capitalized"
                                            id="newacsuccess"
                                            role="alert"
                                        >
                                            <i className="bi bi-check-circle-fill"></i>{' '}
                                            {values.successmsg}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="py-2"></div>
                                )}
                                <div className="row mb-3">
                                    <div className="col-md-12 d-grid">
                                        <button
                                            type="submit"
                                            className="btn btn-outline-danger block py-3 fifth-step"
                                            disabled={
                                                values.name == '' || invalid
                                            }
                                        >
                                            <b className="fs-5">Add</b>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
// export default NewAccount;
