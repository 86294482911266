import React from "react";
import { Container } from 'react-bootstrap';


function Passbook(){
  return (
    <Container fluid={true} className="container">
      <h1>Passbook</h1>
    </Container>
  )
}

export default Passbook;
