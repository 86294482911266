import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Routes, Route, Link, Navigate } from 'react-router-dom'
import { Header, Dialog, Menu } from 'components'
import Login from 'views/Auth/Login'
import Profile from 'views/Auth/Profile'
import Cashbook from 'views/Cashbook'
import Passbook from 'views/Passbook'
import Khatabook from 'views/Khatabook'
import Settings from 'views/Settings'
import NoMatch from 'views/NoMatch.js'
import Khata from 'views/Khata'
import Public from 'views/Khata/Public'
import NewTxn from 'views/Forms/NewTxn'
import NewAccount from 'views/Forms/NewAccount'
import NoKhata from 'views/NoKhata'
import Dummy from 'views/Dummy'
import { auth } from 'services'
import EditTxn from 'views/Forms/EditTxn'
import EditAccount from 'views/Forms/EditAccount'
import Onboard from 'views/Onboard'
import Committee from 'views/Committee'
import Club from 'views/Committee/Club'
import Onboarding from 'views/Onboarding'
import { getTransctions } from 'redux/actions/transctionAction'
import { getReduxAccounts } from 'redux/actions/accountActions'
import { useDispatch, useSelector } from 'react-redux'
import {
    getAllAccounts,
    getAllRcTransactions,
    getAllTransactions
} from 'services/api.service'
import OnboardingTasksModal from 'components/onboarding-tasks'
import { getRcTransctions } from 'redux/actions/rctransctionAction'
import { Toaster } from 'sonner'
import EditRTxn from 'views/Forms/Recursive/EditRtxn'

const user = auth.fetchUser()

function App() {
    const dispatch = useDispatch()
    useEffect(() => {
        getAllTransactions().then((res) => {
            dispatch(getTransctions(res))
        })
        getAllRcTransactions().then((res) => {
            dispatch(getRcTransctions(res))
        })
        getAllAccounts().then((res) => {
            dispatch(getReduxAccounts(res))
        })
    }, [])

    const Template = ({ children }) => {
        return (
            <div className="row m-0">
                <div className="col-md-4 col-12 px-0 border-end border-secondary khatabook-background">
                    <Khatabook />
                </div>
                <div className="col-md-8 col-12 p-0 m-0">{children}</div>
            </div>
        )
    }

    return (
        <div className="vh-100 vw-100 overflow-hidden">
            <Dialog />
            <Toaster />
            <PrivateRouteData>
                <NewTxn />
                <EditTxn />
                <EditRTxn />
                <NewAccount />
                <EditAccount />
                <Menu />
                <Onboard />
                <OnboardingTasksModal />
                <Onboarding />
            </PrivateRouteData>
            <Routes>
                <Route path="/dummy" element={<Dummy />} />
                <Route path="/login" element={<Login type="login" />} />
                <Route path="/funding" element={<Committee />} />
                <Route path="/club">
                    <Route
                        path=":id"
                        element={
                            <PrivateRoute>
                                <Club />
                            </PrivateRoute>
                        }
                    />
                </Route>
                <Route
                    path="/profile"
                    element={
                        <PrivateRoute>
                            <Profile />{' '}
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/newtxn"
                    element={
                        <PrivateRoute>
                            <NewTxn />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/newaccount"
                    element={
                        <PrivateRoute>
                            <NewAccount />
                        </PrivateRoute>
                    }
                />
                <Route path="/khata">
                    <Route
                        path=":id"
                        element={
                            <PrivateRoute>
                                <Template>
                                    <Khata />
                                </Template>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/khata/"
                        element={
                            <PrivateRoute>
                                <Template>
                                    <NoKhata />
                                </Template>
                            </PrivateRoute>
                        }
                    />
                </Route>
                <Route path="/p">
                    <Route path=":id" element={<Public />} />
                </Route>
                <Route
                    path="/"
                    element={
                        <PrivateRoute>
                            <Template>
                                <NoKhata />
                            </Template>
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/passbook"
                    element={
                        <PrivateRoute>
                            <Passbook />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/cashbook"
                    element={
                        <PrivateRoute>
                            <Cashbook />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/khatabook"
                    element={
                        <PrivateRoute>
                            <Khatabook />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/settings"
                    element={
                        <PrivateRoute>
                            <Settings />
                        </PrivateRoute>
                    }
                />
                <Route path="*" element={<NoMatch />} />
            </Routes>
            {/* <Footer /> */}
        </div>
    )
}

const PrivateRoute = ({ children }) => {
    if (!user) {
        return <Navigate to="/login" />
    }
    return children
}

const PrivateRouteData = ({ children }) => {
    if (user && !window.location.href.includes('/p/')) {
        return (
            <>
                <Header />
                {children}
            </>
        )
    } else return null
}

export default App
