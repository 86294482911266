import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isValidPhoneNumber } from 'react-phone-number-input'
import {
    BrowserRouter as Router,
    Route,
    Link,
    useNavigate,
    useLocation,
    Navigate
} from 'react-router-dom'
import { auth, sms } from 'services'
const user = auth.fetchUser()
import { Images } from 'themes'

const Login = (props) => {
    const { type } = props
    console.log('type', type)

    const countries = [
        {
            name: 'india',
            country_code: 'IN',
            dialing_code: '91',
            flag_url: Images.india
        },
        {
            name: 'nepal',
            country_code: 'NP',
            dialing_code: '977',
            flag_url: Images.nepal
        },
        {
            name: 'bangladesh',
            country_code: 'BD',
            dialing_code: '880',
            flag_url: Images.bangladesh
        },
        {
            name: 'pakistan',
            country_code: 'PK',
            dialing_code: '92',
            flag_url: Images.pakistan
        }
    ]
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [selectedCountry, setSelectedCountry] = useState(0)
    const [otpSent, setOtpSent] = useState(false)
    const [invalid, setInValid] = useState(true)
    const [otpVerified, setOtpVerified] = useState(false)
    const [resendWaitTime, setResendWaitTime] = useState(30)
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState({
        phone: '',
        otp: '',
        errormsg: '',
        successmsg: ''
    })

    const failure = (message) => {
        setValues({
            ...values,
            successmsg: '',
            errormsg: message
        })

        setTimeout(() => {
            setValues({
                ...values,
                errormsg: ''
            })
        }, 5000)
    }

    const success = (message) => {
        setValues({
            ...values,
            errormsg: '',
            successmsg: message
        })

        setTimeout(() => {
            setValues({
                ...values,
                successmsg: ''
            })
        }, 5000)
    }

    useEffect(() => {
        if (resendWaitTime && otpSent && !otpVerified)
            setTimeout(() => {
                setResendWaitTime(() => resendWaitTime - 1)
                // console.log(resendWaitTime);
            }, 1000)
    }, [resendWaitTime])

    useEffect(() => {
        // console.log("+91" + phone + ":", isPossiblePhoneNumber("+91" + phone), isValidPhoneNumber("+91" + phone));
        let temp =
            isValidPhoneNumber(
                '+' + countries[selectedCountry].dialing_code + values.phone
            ) || values.phone == ''
        setInValid(!validatePhone())
    }, [values.phone])

    const validatePhone = () => {
        const temp1 =
            isValidPhoneNumber(
                '+' + countries[selectedCountry].dialing_code + values.phone
            ) || values.phone == ''
        let temp2
        switch (selectedCountry) {
            case 0:
                temp2 = values.phone.length == 10
                break
            case 1:
                temp2 = values.phone.length == 8
                break
            case 2:
                temp2 = values.phone.length == 10 || values.phone.length == 9
                break
            case 3:
                temp2 = values.phone.length == 10
                break

            default:
                temp2 = false
                break
        }
        // console.log(temp1, temp2);
        return temp1 && temp2
    }

    const handlePhoneChange = (e) => {
        const target = e.target
        setValues((values) => ({
            ...values,
            phone: target.value,
            errormsg: ''
        }))
    }

    const handleOtpChange = (e) => {
        const target = e.target
        setValues((values) => ({
            ...values,
            otp: target.value,
            errormsg: ''
        }))
    }

    const sendOTP = (e) => {
        e.preventDefault()
        // console.log("+" + countries[selectedCountry].dialing_code + values.phone, countries[selectedCountry].dialing_code + values.phone)
        const temp = isValidPhoneNumber(
            '+' + countries[selectedCountry].dialing_code + values.phone
        )
        if (temp) {
            setOtpVerified(() => false)
            sms.sendOTP(countries[selectedCountry].dialing_code + values.phone)
                .then((result) => {
                    // console.log(result);
                    if (result.type == 'success') {
                        // otp sent
                        setResendWaitTime(29)
                        setOtpSent(() => true)
                        success(
                            'OTP sent to ' + values.phone + ' successfully !'
                        )
                    } else {
                        // error
                        failure(result.message)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {
            failure('Invalid Phone no.')
        }
    }

    const resendviasms = (e) => {
        e.preventDefault()
        // alert("resend")
        sms.resendOTP(
            countries[selectedCountry].dialing_code + values.phone,
            'text'
        ).then((result) => {
            success('OTP resent successfully!')
            setResendWaitTime(29)
        })
    }

    const resendviacall = (e) => {
        e.preventDefault()
        // alert("resend")
        sms.resendOTP(
            countries[selectedCountry].dialing_code + values.phone,
            'call'
        ).then((result) => {
            success('OTP resent via call successfully!')
            setResendWaitTime(29)
        })
    }

    const verifyFunction = (e) => {
        e.preventDefault()
        sms.verifyOTP(
            countries[selectedCountry].dialing_code + values.phone,
            values.otp
        )
            .then((result) => {
                if (!result.type) {
                    setLoading(() => true)
                    success('OTP verified successfully!')
                    if (
                        !localStorage.getItem(
                            `Parse/${process.env.REACT_APP_APPID}/currentUser`
                        )
                    ) {
                        localStorage.setItem(
                            `Parse/${process.env.REACT_APP_APPID}/currentUser`,
                            JSON.stringify(result)
                        )
                    }
                    if (type == 'login') {
                        window.location = '/'
                    } else {
                        window.location = '/onboarding'
                    }
                } else if (result.message == 'OTP not match') {
                    failure('OTP is incorrect! Please enter correct otp.')
                } else {
                    failure('Something went wrong!')
                }
            })
            .catch((error) => {
                console.log(error)
                failure(error.message)
            })
    }

    if (user && type == 'login') {
        return <Navigate to="/" />
    } else if (user && type == 'signup') {
        return <Navigate to="/onboarding" />
    }

    return (
        <div id="loginblock">
            <div className="align-items-center d-flex justify-content-between vh-100 login-background">
                <div className="mx-auto col-md-4 col-xs-6 p-4 bg-light rounded-4 shadow border border-1 border-danger bg-opacity-50">
                    <div className="d-flex">
                        <img src={Images.logo} className="mx-auto w-25"></img>
                    </div>
                    <span className="d-flex w-full gap-2 justify-content-center">
                        <h1 className="my-auto text-center mb-3">
                            {t(type == 'signup' ? 'Signup to' : 'Login to')}
                        </h1>
                        <h1 className="my-auto text-center text-danger mb-3">
                            {t('Bahi Khata')}
                        </h1>
                    </span>
                    <form onSubmit={otpSent ? verifyFunction : sendOTP}>
                        <div className="form-group mb-4 w-100 d-flex">
                            <span className="dropdown">
                                <button
                                    className="btn btn-light dropdown-toggle me-1"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    title={countries[
                                        selectedCountry
                                    ].name.toUpperCase()}
                                >
                                    <img
                                        src={
                                            countries[selectedCountry].flag_url
                                        }
                                        className="login-flag me-1"
                                    ></img>
                                    {countries[selectedCountry].country_code +
                                        ' (+' +
                                        countries[selectedCountry]
                                            .dialing_code +
                                        ')'}{' '}
                                    <i className="bi bi-chevron-down"></i>
                                </button>
                                <ul className="dropdown-menu">
                                    {countries.map((country, index) => (
                                        <li>
                                            <button
                                                className="dropdown-item"
                                                title={country.name.toUpperCase()}
                                                onClick={() =>
                                                    setSelectedCountry(
                                                        () => index
                                                    )
                                                }
                                                type="button"
                                            >
                                                <img
                                                    src={country.flag_url}
                                                    className="login-flag"
                                                ></img>
                                                {country.country_code +
                                                    ' (+' +
                                                    country.dialing_code +
                                                    ')'}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </span>
                            <div className="w-100">
                                <input
                                    type="tel"
                                    className={
                                        invalid
                                            ? 'form-control is-invalid'
                                            : values.phone
                                            ? 'form-control is-valid'
                                            : 'form-control'
                                    }
                                    id="inputPhone"
                                    aria-describedby="phoneHelp"
                                    placeholder="Phone no."
                                    value={values.phone}
                                    onChange={handlePhoneChange}
                                    required
                                />
                                <div className="invalid-feedback">
                                    Please enter valid phone no.
                                </div>
                            </div>
                        </div>
                        {/* <br /> */}
                        <div
                            className={
                                otpSent
                                    ? 'input-group shadow-sm rounded mt-3'
                                    : 'd-none'
                            }
                        >
                            <input
                                type="number"
                                className="form-control shadow-none border-end-0"
                                placeholder="OTP"
                                value={values.otp}
                                onChange={handleOtpChange}
                                required={otpSent}
                                aria-describedby="loginOtp"
                            />
                        </div>

                        <div
                            className={
                                otpSent
                                    ? 'h-auto row text-dark pb-2 justify-content-center mt-2'
                                    : 'd-none'
                            }
                        >
                            <span className="resend-otp mb-1 w-auto">
                                Please wait {resendWaitTime}s before sending
                                another OTP
                            </span>
                            <button
                                onClick={resendviasms}
                                className={
                                    resendWaitTime > 0
                                        ? 'd-none'
                                        : 'resend-otp mx-2 btn btn-danger w-auto my-1'
                                }
                                disabled={resendWaitTime > 0}
                            >
                                Resend via sms
                            </button>
                            <button
                                onClick={resendviacall}
                                className={
                                    resendWaitTime > 0
                                        ? 'd-none'
                                        : 'resend-otp mx-2 btn btn-danger w-auto my-1'
                                }
                                disabled={resendWaitTime > 0}
                            >
                                Resend via call
                            </button>
                        </div>
                        {values.errormsg ? (
                            <div className="form-group text-start animate__animated animate__pulse">
                                <div
                                    className="form-check-label alert alert-danger text-capitalized"
                                    id="loginerror"
                                >
                                    <i className="bi bi-exclamation-circle-fill"></i>{' '}
                                    {values.errormsg}
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                        {values.successmsg ? (
                            <div className="form-group text-start">
                                <div
                                    className="form-check-label alert alert-success text-capitalized"
                                    id="loginsuccess"
                                    role="alert"
                                >
                                    <i className="bi bi-check-circle-fill"></i>{' '}
                                    {values.successmsg}
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                        <div className="d-grid">
                            <button
                                type="submit"
                                disabled={invalid}
                                className={
                                    loading
                                        ? 'btn btn-danger shadow disabled'
                                        : 'btn btn-danger shadow'
                                }
                                id="loginsubmitbtn"
                            >
                                <span
                                    className={
                                        loading
                                            ? 'spinner-border spinner-border-sm'
                                            : ''
                                    }
                                ></span>
                                {loading
                                    ? ' Loading...'
                                    : otpSent
                                    ? 'Verify OTP'
                                    : 'Send OTP'}
                            </button>
                        </div>
                    </form>
                    <br />
                    <div className="row">
                        {/* <div className="col-md-12 text-center d-flex justify-content-around"> */}
                        {/* <Link to="/emaillogin" className="btn btn-light shadow mt-2 w-auto mx-auto" size="lg">Login with Email</Link>
                        <Link to="/signup" className="btn btn-light shadow mt-2 w-auto mx-auto" size="lg">Create a new Account</Link> */}
                        {/* <Link to="/forget" className="btn btn-light shadow mt-2 w-auto mx-auto" size="lg">Forgot Password?</Link> */}
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
