import React, { useEffect, useState } from 'react'
import { ListGroup, Row, Col } from 'react-bootstrap'
import accounting from 'accounting'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faArrowRightLong,
    faXmark,
    faPencil,
    faTrash
} from '@fortawesome/free-solid-svg-icons'
import { useNavigate, useParams } from 'react-router-dom'
import { api } from 'services'
import 'animate.css'

function Account(props) {
    const [selected, setSelected] = useState(false)
    const { id } = useParams()
    const account = props.data
    const nowTime = new Date().getTime()
    const remindBool = account.get('date')
        ? nowTime > account.get('date').getTime() && account.get('enabled')
        : null
    let navigate = useNavigate()
    if (selected)
        document.body.addEventListener('click', () => {
            setSelected(false)
        })

    const goToKhata = () => {
        navigate('/khata/' + account.id)
    }

    const cancelledView = () => {
        return (
            <>
                <Col md={7} sm={7} xs={7} align="left">
                    <a
                        role="button"
                        className="px-3 text-decoration-line-through text-muted fs-5"
                    >
                        {account.get('name').length > 15
                            ? account.get('name').substring(0, 15) + '...'
                            : account.get('name')}
                    </a>
                </Col>
                <Col md={3} sm={3} xs={3}>
                    <span className="fs-5 text-success">
                        <span className="text-muted text-decoration-line-through">
                            {accounting.formatMoney(
                                Math.abs(account.get('balance')),
                                '₹',
                                0
                            )}
                        </span>
                    </span>
                </Col>
                <Col xs={1} className="dropstart me-2 index-high">
                    <a
                        className="text-black rounded-circle btn bg-not-transparent-on-hover high-z-index"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        title="More options"
                        onClick={() => setSelected(!selected)}
                    >
                        <i className="bi bi-three-dots-vertical"></i>
                    </a>
                    <ul
                        className="dropdown-menu row"
                        onMouseEnter={() => setSelected(true)}
                    >
                        <li className="col-12 py-1">
                            <button
                                className="text-decoration-none w-100 btn-success btn text-start dropdown-item"
                                onClick={() => props.onReopen(account)}
                            >
                                <i className="bi bi-arrow-clockwise"></i>
                                <span className="mx-2">Reopen Account</span>
                            </button>
                        </li>
                        {/* <li className="col-12 py-1">
                            <button
                                className="text-decoration-none w-100 btn-danger btn text-start dropdown-item"
                                onClick={() => props.onDel(account)} data-bs-toggle="modal" data-bs-target="#delModal"
                            >
                                <i className="bi bi-trash"></i>
                                <span className="mx-2">Delete Account</span>
                            </button>
                        </li> */}
                    </ul>
                </Col>
            </>
        )
    }

    //
    const normalView = () => {
        return (
            <a role="button" onClick={goToKhata}>
                <Row>
                    <Col md={7} sm={7} xs={7} align="left">
                        <div className="px-3 khatabook-account-name text-dark fs-5 fw-bold pe-auto text-overflow-ellipsis">
                            {remindBool ? (
                                <i
                                    className="bi bi-patch-exclamation-fill text-danger me-2"
                                    title="Payment Due!"
                                ></i>
                            ) : null}
                            {account.get('name').length > 20
                                ? account.get('name').substring(0, 20) + '...'
                                : account.get('name')}
                        </div>
                    </Col>
                    <Col md={3} sm={3} xs={3}>
                        <span
                            className={
                                account.get('balance') < 0
                                    ? 'fs-5 fw-bold text-danger'
                                    : 'fs-5 fw-bold text-success'
                            }
                        >
                            <span className="">
                                {accounting.formatMoney(
                                    Math.abs(account.get('balance')),
                                    '₹',
                                    0
                                )}
                            </span>
                        </span>
                    </Col>
                    <Col xs={1} className="dropstart index-high">
                        <a
                            className="text-black rounded-circle btn bg-not-transparent-on-hover high-z-index"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            title="More options"
                            onClick={() => setSelected(!selected)}
                        >
                            <i className="bi bi-three-dots-vertical"></i>
                        </a>
                        <ul
                            className="dropdown-menu row"
                            onMouseEnter={() => setSelected(true)}
                        >
                            <li className="col-12 py-1">
                                <button
                                    className="text-decoration-none text-black w-100 btn btn-outline-dark text-start dropdown-item"
                                    onClick={() => props.onEdit(account)}
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#editAccDrawer"
                                    aria-controls="editAccDrawer"
                                >
                                    <FontAwesomeIcon
                                        icon={faPencil}
                                        size="1x"
                                    />
                                    <span className="mx-2">Edit</span>
                                </button>
                            </li>
                            {account.get('balance') != 0 ? null : (
                                <li className="col-12 py-1">
                                    <button
                                        className="text-decoration-none text-black w-100 btn btn-danger text-start dropdown-item"
                                        onClick={() => props.onClose(account)}
                                        data-bs-toggle="modal"
                                        data-bs-target="#khataBookModal"
                                    >
                                        <i className="bi bi-x-circle-fill"></i>
                                        <span className="mx-2">Close</span>
                                    </button>
                                </li>
                            )}
                        </ul>
                    </Col>
                </Row>
            </a>
        )
    }

    // text-decoration-line-through text-muted
    return (
        <ListGroup.Item
            className={
                account.id == id
                    ? selected
                        ? 'bg-danger bg-opacity-10 high-z-index'
                        : 'bg-danger bg-opacity-10'
                    : selected
                    ? 'bg-transparent py-2 high-z-index'
                    : 'bg-transparent py-2'
            }
        >
            {/* {account.id == id ? "bg-danger bg-opacity-25 rounded-3 border-none" : "bg-transparent border-none"} */}
            <Row className="align-middle py-2">
                {account.get('cancelled') ? cancelledView() : normalView()}
            </Row>
        </ListGroup.Item>
    )
}

export default Account
