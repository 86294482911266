import { useState, useEffect } from 'react'

export default function useDevice() {
    const [deviceType, setDeviceType] = useState(
        /**  @type {'mobile'| 'tablet' | 'desktop'} */ ('desktop')
    )

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setDeviceType('mobile')
            } else if (window.innerWidth < 992) {
                setDeviceType('tablet')
            } else {
                setDeviceType('desktop')
            }
        }

        window.addEventListener('resize', handleResize)
        handleResize()

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return deviceType
}
