import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function NoMatch(){
  return (
    <Container>
      <Row className="py-5">
        <Col md={12} align="center" >
          <h1>This Page doesn't exist.</h1>
          <a href="/" className="my-2 fw-bold btn btn-outline-danger">Go Home</a>
          <br />
          <a href="/" className="my-2 fw-bold btn btn-outline-danger">Go Back</a>
        </Col>
      </Row>
    </Container>
  )
}

export default NoMatch;
