import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "services";
import { findFreshUser } from "services/api.service";
import { sendVerificationEmail } from "services/auth.service";

function Profile() {
  const [user, setUser] = useState(auth.fetchUser());
  let navigate = useNavigate();
  const [editing, setEditing] = useState(false);
  const [values, setValues] = useState({
    // name: user.get("name"),
    phone: user.get("phone"),
    username: user.get("username"),
    email: user.get("email"),
    businessName: user.get("businessName"),
    typeOfBusiness: user.get("typeOfBusiness"),
    errormsg: '',
    successmsg: ''
  })

  const save = (e) => {
    e.preventDefault();
    // if (values.name != user.get("name")) {
    //   auth.setName(values.name).then(() => {
    //     success('Updated Succesfully')
    //   }).catch((err) => {
    //     failure(err.message)
    //   })
    // }
    if (values.phone != user.get("phone")) {
      auth.setPhone(values.phone).then((response) => {
        success('Updated Succesfully')
      }).catch((err) => {
        failure(err.message)
      })
    }
    if (values.username != user.get("username")) {
      auth.setUsername(values.username).then(() => {
        success('Updated Succesfully')
      }).catch((err) => {
        failure(err.message)
      })
    }
    if (values.email != user.get("email")) {
      auth.setEmail(values.email).then(() => {
        success('Updated Succesfully')
      }).catch((err) => {
        failure(err.message)
      })
    }

    if (values.businessName != user.get("businessName")) {
      auth.setBusinessName(values.businessName).then(() => {
        success('Updated Succesfully')
      }).catch((err) => {
        failure(err.message)
      })
    }

    if (values.typeOfBusiness != user.get("typeOfBusiness")) {
      auth.setTypeOfBusiness(values.typeOfBusiness).then(() => {
        success('Updated Succesfully')
      }).catch((err) => {
        failure(err.message)
      })
    }

    setEditing(false);
  }

  const edit = () => {
    setEditing(true);
  }

  const nameHandler = (e) => {
    setValues({
      ...values,
      name: e.target.value,
      errormsg: '',
      successmsg: ''
    })
  }

  const success = (message) => {
    setValues({
      ...values,
      successmsg: message,
      errormsg: ''
    })

    setTimeout(() => {
      setValues({
        ...values,
        successmsg: ""
      })
    }, 5000);
  }

  const failure = (message) => {
    setValues({
      name: user.get("name"),
      phone: user.get("phone"),
      username: user.get("username"),
      email: user.get("email"),
      businessName: user.get("businessName"),
      typeOfBusiness: user.get("typeOfBusiness"),
      errormsg: message
    })

    setTimeout(() => {
      setValues({
        ...values,
        errormsg: ""
      })
    }, 5000);
  }

  const emailHandler = (e) => {
    setValues({
      ...values,
      email: e.target.value,
      errormsg: '',
      successmsg: ''
    })
  }

  const usernameHandler = (e) => {
    setValues({
      ...values,
      username: e.target.value,
      errormsg: '',
      successmsg: ''
    })
  }

  const businessNameHandler = (e) => {
    setValues({
      ...values,
      businessName: e.target.value,
      errormsg: '',
      successmsg: ''
    })
  }

  const typeOfBusinessHandler = (e) => {
    setValues({
      ...values,
      typeOfBusiness: e.target.value,
      errormsg: '',
      successmsg: ''
    })
  }

  const phoneHandler = (e) => {
    setValues({
      ...values,
      phone: e.target.value,
      errormsg: '',
      successmsg: ''
    })
  }

  const back = () => {
    navigate(-1);
    // console.log(user.get("emailVerified"));
  }

  const refresh = () => {
    findFreshUser(user.id).then((result) => {
      setUser(() => result);
      success("User data updated successfully");
      localStorage.setItem(`Parse/${process.env.REACT_APP_APPID}/currentUser`, JSON.stringify(result));
    }).catch((err) => {
      err;
      failure(err.message);
    })
  }
  const verifyEmail = (e) => {
    e.preventDefault();
    sendVerificationEmail(user.get("email")).then((result) => {
      success(result);
    }).catch((cause) => {
      failure(cause);
    })
  }

  return (
      <div className="height-full">
          <nav className="row bg-danger py-2 justify-content-around h4 text-light">
              <div className="col-1 my-auto fs-2" align="right">
                  <a
                      role="button"
                      className="my-2 "
                      onClick={back}
                      title="Back"
                  >
                      <i
                          className="bi bi-arrow-left-square"
                          style={{ fontSize: 32, color: 'white' }}
                      ></i>
                  </a>
              </div>
              <div className="col-10 text-center my-auto h3">
                  Your Profile Details
              </div>
              {/* <div className="col-1 my-auto fs-2" align="center">
          <a role="button" className="btn btn-light my-2 ms-auto" onClick={refresh} title="Refresh User">
            <i className="bi bi-arrow-clockwise"></i>
          </a>
        </div> */}
          </nav>
          <div className="ms-8">
              <table className="my-4 container table table-striped">
                  <div className="row text-danger">
                      <tbody className="mb-4">
                          {/* <tr>
                              <th
                                  scope="row"
                                  className="fw-bold fs-4 w-auto my-auto py-3"
                              >
                                  Name :
                              </th>
                              <td>
                                  <input
                                      className="w-100 my-auto ms-5 form-control"
                                      type="text"
                                      value={values.name}
                                      onChange={nameHandler}
                                      title={values.name}
                                      disabled={!editing}
                                  />
                              </td>
                          </tr> */}
                          <tr>
                              <th
                                  scope="row"
                                  className="fw-bold fs-4 w-auto my-auto py-3"
                              >
                                  Phone :
                              </th>
                              <td>
                                  <input
                                      className="w-100 my-auto ms-5 form-control"
                                      type="tel"
                                      value={values.phone}
                                      onChange={phoneHandler}
                                      title={values.phone}
                                      disabled={true}
                                  />
                              </td>
                          </tr>
                          <tr>
                              <th
                                  scope="row"
                                  className="fw-bold fs-4 w-auto my-auto py-3"
                              >
                                  Business Name :
                              </th>
                              <td>
                                  <input
                                      className="w-100 my-auto ms-5 form-control"
                                      type="text"
                                      onChange={businessNameHandler}
                                      value={values.businessName}
                                      title={values.businessName}
                                      disabled={!editing}
                                  />
                              </td>
                          </tr>
                          <tr>
                              <th
                                  scope="row"
                                  className="fw-bold fs-4 w-auto my-auto py-3"
                              >
                                  Type of Business :
                              </th>
                              <td>
                                  <select
                                      id="typeOfBusiness"
                                      className="w-100 my-auto ms-5 form-select"
                                      defaultValue={values.typeOfBusiness}
                                      disabled={!editing}
                                      onChange={typeOfBusinessHandler}  
                                  >
                                      <option value="service">
                                          Service
                                      </option>
                                      <option value="manufacturing">
                                          Manufacturing
                                      </option>
                                      <option value="trading">
                                          Trading
                                      </option>
                                  </select>
                              </td>
                          </tr>
                          {/* <tr>
                <th scope="row" className="fw-bold fs-4 w-auto my-auto py-3">Username :</th>
                <td colspan="2">
                  <input className="w-100 my-auto ms-5 form-control" type="text" value={values.username} onChange={usernameHandler} title={values.username} disabled={true} />
                </td>
              </tr> */}
                          <tr>
                              <th
                                  scope="row"
                                  className="fw-bold fs-4 w-auto my-auto py-3"
                              >
                                  Email :
                              </th>
                              <td colspan="2" className="position-relative">
                                  <input
                                      className="w-100 my-auto ms-5 form-control"
                                      type="email"
                                      value={values.email}
                                      onChange={emailHandler}
                                      title={values.email}
                                      disabled={!editing}
                                  />
                                  {user.get('emailVerified') ? (
                                      <span className="position-absolute start-0 ms-5 mt-2 text-success">
                                          <i className="bi bi-patch-check-fill"></i>{' '}
                                          Email Verified!
                                          <i
                                              className="bi bi-arrow-clockwise ps-2"
                                              onClick={refresh}
                                              role="button"
                                              title="Refresh"
                                          ></i>
                                      </span>
                                  ) : (
                                      <span className="position-absolute start-0 ms-5 mt-2 text-danger d-flex w-100 justify-content-around">
                                          <span className="my-auto">
                                              <i className="bi bi-shield-fill-exclamation"></i>{' '}
                                              Email not verified!
                                          </span>
                                          <button
                                              onClick={verifyEmail}
                                              className="btn btn-danger"
                                              title="Send verification Email"
                                          >
                                              Verify
                                          </button>
                                          <i
                                              className="bi bi-arrow-clockwise ps-2 my-auto"
                                              onClick={refresh}
                                              role="button"
                                              title="Refresh"
                                          ></i>
                                      </span>
                                  )}
                              </td>
                          </tr>
                      </tbody>
                      {values.errormsg ? (
                          <div className="form-group text-start animate__animated animate__pulse">
                              <div
                                  className="form-check-label alert alert-danger text-capitalized border-danger border"
                                  id="editacerror"
                                  role="alert"
                              >
                                  <i className="bi bi-exclamation-circle-fill"></i>{' '}
                                  {values.errormsg}
                              </div>
                          </div>
                      ) : (
                          <div className="py-2"></div>
                      )}
                      {values.successmsg ? (
                          <div className="form-group text-start mb-3">
                              <div
                                  className="form-check-label alert alert-success text-capitalized border-success border"
                                  id="editacsuccess"
                                  role="alert"
                              >
                                  <i className="bi bi-check-circle-fill"></i>{' '}
                                  {values.successmsg}
                              </div>
                          </div>
                      ) : (
                          <div className="py-2"></div>
                      )}
                      <button
                          className="btn btn-danger py-3"
                          onClick={editing ? save : edit}
                      >
                          <b className="fs-5">{editing ? 'Save' : 'Edit'}</b>
                      </button>
                  </div>
              </table>
          </div>
      </div>
  )
}
export default Profile;
