const images = {
  logo: require('../images/logo.png'),
  background: require('../images/background.png'),
  india: require('../images/india.png'),
  nepal: require('../images/nepal.png'),
  bangladesh: require('../images/bangladesh.png'),
  pakistan: require('../images/pakistan.png')
};

export default images;
