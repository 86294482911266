import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faArrowCircleLeft, faArrowLeft, faImage } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';
import { api } from 'services';
import { useDispatch, useSelector } from "react-redux";
import { getTransctions } from "redux/actions/transctionAction";
// import txnaudio from "../images/google_pay-[AudioTrimmer.com].mp3"

function EditTxn(props) {
  const [multipler, setMultipler] = useState(1);
  const [amount, setAmount] = useState();
  const [dateValue, onDateChange] = useState(new Date());
  // const [accounts, setAccounts] = useState([]);
  const [txnDetail, setTxnDetail] = useState("");
  const allTransction = useSelector((state) => state.transction.allTransctions);
  const copiedTransactions = allTransction ? [...allTransction] : null;
  const updateData = useSelector((state) => state.updater.allUpdates);
  const forEdit = updateData? updateData.editTxn : null;
  const dispatch = useDispatch();
  let navigate = useNavigate();

  // useEffect(() => {
  //   api.getActiveAccounts().then((accounts) => {
  //     setAccounts(accounts);
  //   });
  // }, []);

  useEffect(() => {
    // console.log(updateData);
      if (forEdit?.id) {
        if (forEdit.get("accountId")) {
          let accname = forEdit.get("accountId").get("name");
          document.getElementById("edittxnacc").value = accname ? accname : "";
        }
        if (forEdit.get("amount") < 0) {
          setMultipler(-1)
          setAmount(-forEdit.get("amount"));
        } else {
          setMultipler(1)
          setAmount(forEdit.get("amount"));
        }
        onDateChange(forEdit.get("date"));
        setTxnDetail(forEdit.get("detail"))
      }
  }, [updateData]);

  const amountChangeHandler = (e) => {
    e.preventDefault();
    if (e.target.value < 0) {
      setMultipler(-1)
      setAmount(-e.target.value);
    } else {
      setAmount(e.target.value)
    }
  }

  const edit = (e) => {
    e.preventDefault();
    let id = updateData.editTxn.id;
    let detail = txnDetail;
    if (dateValue !== forEdit.get("date")) {
      // console.log("date not same");
      const aa = forEdit.get("date");
      dateValue.setHours(aa.getHours(),aa.getMinutes(), aa.getSeconds());
    }
    if (amount > 0 && amount <= 9999999) {
      api.updateTransaction(id, multipler * amount, detail, dateValue).then((result) => {
        updateTransactionsState(result);
        // props.onTriggerTxns();
      });
      // Clear the form
      resetform();
    } else {
      alert("Enter an amount between 1 and 1,00,00,000₹.")
    }

  }

  const updateTransactionsState = (newTxn) => {
    const arrayIndex = copiedTransactions.findIndex(obj => obj.id == newTxn.id);
    copiedTransactions[arrayIndex] = newTxn;
    dispatch(getTransctions(copiedTransactions));
  }

  document.getElementById('editTxnDrawer')?.addEventListener('hide.bs.offcanvas', function (e) {
    resetform();
  });

  const resetform = () => {
    let txnform = document.getElementById("EditTxnform");
    txnform.reset();
    onDateChange(new Date());
    setTxnDetail("");
    setAmount();
    document.getElementById("edittxnacc").value = "";
  }

  return (
    <div className="offcanvas offcanvas-end text-danger" tabIndex="-1" id="editTxnDrawer" aria-labelledby="offcanvasExampleLabel">
      <div className="offcanvas-header bg-danger text-light">
        <h3 className="offcanvas-title">Edit Transaction</h3>
        <button type="button" className="btn-close text-reset bg-light" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => resetform()}></button>
      </div>
      <div className="offcanvas-body">
        <div className="container-fluid bg-white mb-5">
          <div className="row">
            <div className="col-12">
              <form onSubmit={edit} id="EditTxnform">
                <div className="row py-3">
                  <div className="col-md-6">
                    <input type="number" className="d-none" id="editmultipler" />
                    <input type="number" className="form-control" id="edittxnamount" placeholder="Amount" value={amount} onChange={amountChangeHandler} min="1" max="9999999" disabled={true} required title="You cannot edit amount" />
                  </div>
                  <div className="col-md-6 border-present mt-4 mt-md-0">
                    <DatePicker className="form-control w-100" id="edittxndate" value={dateValue} onChange={onDateChange} maxDate={new Date()} />
                  </div>
                </div>
                <div className="py-3">
                  <input type="text" className="form-control" id="edittxnacc" placeholder="Account Name" list="accountOptions" title="You cannnot edit account" disabled />
                </div>
                <div className="py-3">
                  <input type="text" className="form-control" id="edittxndetail" placeholder="Details" maxLength="50" value={txnDetail} onChange={(e) => setTxnDetail(e.target.value)} />
                </div>
                <div className="row my-3">
                  <div className="col-md-12 d-grid">
                    <button type="submit" className="btn btn-outline-danger block py-3" data-bs-dismiss="offcanvas">
                      <b className="fs-5">Update</b>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default EditTxn;
