import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Container, Row, Col, ListGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCoffee,
    faArrowCircleDown,
    faArrowCircleUp,
    faArrowCircleLeft,
    faArrowCircleRight,
    faPencil
} from '@fortawesome/free-solid-svg-icons'
import accounting from 'accounting'
import moment from 'moment'
import DatePicker from 'react-date-picker'
import 'index.css'
import { mock, api } from 'services'
import OpenBalance from 'components/OpenBalance'
import CloseBalance from 'components/CloseBalance'
import Txn from './Txn'
import { ConfirmDelete, Modal } from 'components'
//import { Loader } from 'components';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setForUpdate } from 'redux/actions/updateAction'
import { useOnboardingStore } from 'store/onboarding-store'
import { debounce } from 'utils'

function Home() {
    const { t } = useTranslation()
    const [txns, setTxns] = useState([])
    const [openBalance, setOpenBalance] = useState(0)
    const [closeBalance, setCloseBalance] = useState(0)
    const [date, setDate] = useState(new Date())
    var [deletionOf, setDeletionOf] = useState({})
    const dispatch = useDispatch()
    const allTransction = useSelector(
        (state) => state.transction.allTransctions
    )
    const copiedTransactions = allTransction ? [...allTransction] : null
    const goToNextStep = useOnboardingStore((state) => state.goToNextStep)

    useEffect(() => {
        const firstDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate() - 1,
            23,
            59,
            59,
            999
        ) //.toISOString();
        const nextDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            23,
            59,
            60,
            0
        ) //'.toISOString();
        if (copiedTransactions) {
            const allExec = () => {
                let trans1 = []
                let opensum = 0
                let closesum = 0

                copiedTransactions?.map((transaction) => {
                    const Date = transaction.get('date')
                    if (Date > firstDate && Date < nextDate) {
                        trans1.push(transaction)
                    }
                    if (
                        Date < firstDate &&
                        transaction.get('cancelled') === false
                    ) {
                        opensum += transaction.get('amount')
                    }
                    if (
                        Date < nextDate &&
                        transaction.get('cancelled') === false
                    ) {
                        closesum += transaction.get('amount')
                    }
                })

                setTxns(trans1)
                setOpenBalance(opensum)
                setCloseBalance(closesum)
            }
            allExec()
        }
    }, [date, allTransction])

    const nextDate = () => {
        const today = new Date()
        const newDate = moment(date).add(1, 'days').toDate()
        if (newDate <= today) {
            setDate(newDate)
        }
        // console.log(today);
    }

    const prevDate = () => {
        const newDate = moment(date).subtract(1, 'days').toDate()
        setDate(newDate)
    }

    const confirmit = (txn) => {
        const toDel = { delTxn: txn }
        dispatch(setForUpdate(toDel))
        // setDeletionOf(txn);
    }

    const edittxn = (data) => {
        const updateData = { editTxn: data }
        dispatch(setForUpdate(updateData))
    }

    const TxnsView = () => {
        return (
            <div className="row">
                <span className="col-md-12">
                    <div className="row background overflow-y-scroll cashbook-scroll">
                        <Col md={6} xs={6} className="cashin">
                            <ListGroup variant="flush" className="mb-5">
                                <ListGroup.Item className="header bg-transparent">
                                    <Row className="align-baseline header">
                                        <Col
                                            md={12}
                                            align="center"
                                            className="text-success"
                                        >
                                            <i className="bi bi-arrow-down-circle-fill fs-3"></i>
                                            <span className="fs-3 text-uppercase px-3">
                                                <b>Cash In</b>
                                            </span>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                                <OpenBalance sum={openBalance} />
                                {txns
                                    .filter((txn) => txn.get('amount') > 0)
                                    .map((txn, i) => {
                                        if (!txn.cancelled) {
                                            return (
                                                <Txn
                                                    data={txn}
                                                    key={i}
                                                    onEdit={edittxn}
                                                    onDel={confirmit}
                                                />
                                            )
                                        }
                                        return null
                                    })}
                            </ListGroup>
                        </Col>
                        <Col md={6} xs={6} className="cashout">
                            <ListGroup variant="flush" className="mb-5">
                                <ListGroup.Item className="bg-transparent">
                                    <Row className="align-baseline">
                                        <Col
                                            md={12}
                                            align="center"
                                            className="text-danger"
                                        >
                                            <i className="bi bi-arrow-up-circle-fill fs-3"></i>
                                            <span className="fs-3 text-uppercase px-3">
                                                <b>Cash Out</b>
                                            </span>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                                {txns
                                    .filter((txn) => txn.get('amount') < 0)
                                    .map((txn, i) => {
                                        if (!txn.cancelled) {
                                            return (
                                                <Txn
                                                    data={txn}
                                                    key={i}
                                                    onEdit={edittxn}
                                                    onDel={confirmit}
                                                />
                                            )
                                        }
                                        return null
                                    })}
                                <CloseBalance sum={closeBalance} />
                            </ListGroup>
                        </Col>
                    </div>
                </span>
            </div>
        )
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row bg-danger py-1">
                    <div className="col-1" align="right">
                        <a
                            onClick={prevDate}
                            className="btn btn-outline-light "
                        >
                            <FontAwesomeIcon
                                icon={faArrowCircleLeft}
                                size="2x"
                                title="Previous Date"
                            />
                        </a>
                    </div>
                    <div className="col-2 offset-md-4 py-2 " align="center">
                        <DatePicker
                            value={date}
                            onChange={(value) => setDate(value)}
                            monthAriaLabel="Month"
                            clearIcon={null}
                            format="dd-MMM"
                            maxDate={new Date()}
                            maxDetail="month"
                            className="datePicker"
                        />
                    </div>
                    <div className="col-1 offset-4" align="left">
                        <a
                            onClick={nextDate}
                            className="btn btn-outline-light "
                            disabled={!moment(date).isBefore(new Date(), 'day')}
                        >
                            <FontAwesomeIcon
                                icon={faArrowCircleRight}
                                size="2x"
                                title="Next Date"
                            />
                        </a>
                    </div>
                </div>

                {TxnsView()}

                <div className="row position-fixed bottom-0 end-0 py-4 px-4">
                    <div className="col-md-12 py-3">
                        <button
                            className="tenth-step btn btn-danger rounded-pill px-4 border-width-med"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#addTxnDrawer"
                            aria-controls="addTxnDrawer"
                            onClick={() => goToNextStep()}
                        >
                            <FontAwesomeIcon
                                icon={faPencil}
                                size="1x"
                                className="my-auto fs-4"
                            />
                            <span className="my-auto fs-3 mx-2 fonts-for-buttons-label">
                                <strong>New Transaction</strong>
                            </span>
                        </button>
                    </div>
                </div>
            </div>

            <ConfirmDelete />
        </>
    )
}

export default Home
