import React, { useState, useEffect } from 'react'
import { Container, ListGroup, Row, Col } from 'react-bootstrap'
import Account from './Account'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faL, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { mock, api } from 'services'
import { useNavigate } from 'react-router-dom'
import {
    getAllAccounts,
    getUserKhatabookFilter,
    setUserKhatabookFilter
} from 'services/api.service'
import { useDispatch, useSelector } from 'react-redux'
import { getReduxAccounts } from 'redux/actions/accountActions'
import { setForUpdate } from 'redux/actions/updateAction'
import { useOnboardingStore } from 'store/onboarding-store'
// import SearchAccount from "./SearchAccount";

function index() {
    const allaccountsData = useSelector((state) => state.accounts.allAccounts)
    const newData = allaccountsData ? [...allaccountsData] : null
    // const [accounts, setAccounts] = useState([]);
    const [searchInput, setSearchInput] = useState('')
    const [filtered, setFiltered] = useState(newData ? newData : [])
    const [closingOf, setClosingOf] = useState({})
    const [deletionOf, setDeletionOf] = useState({})
    const [loading, setLoading] = useState(true)
    const [hideClosed, setHideClosed] = useState(getUserKhatabookFilter())
    const [sortBy, setSortBy] = useState('name')
    const [sortOrder, setSortOrder] = useState(() => false)
    const dispatch = useDispatch()
    const goToNextStep = useOnboardingStore((store) => store.goToNextStep)

    useEffect(() => {
        if (!searchInput) setFiltered(sortFunction(newData))
        setLoading(false)
        // console.log(getUserKhatabookFilter());
    }, [allaccountsData])

    useEffect(() => {
        if (searchInput) {
            // console.log("search input triggerred:-", searchInput);
            searchFiltering()
        } else {
            setFiltered(sortFunction(newData))
        }
    }, [searchInput])

    useEffect(() => {
        // console.log(sortBy, sortOrder);
        setFiltered((array) => sortFunction(array))
    }, [sortBy])

    useEffect(() => {
        // console.log(sortBy, sortOrder);
        let tempArray = filtered.reverse()
        setFiltered(tempArray)
    }, [sortOrder])

    function sortFunction(array) {
        let arr = []
        if (sortBy === 'name') {
            arr = stringSort(array, sortOrder)
        } else {
            arr = numSort(array, sortOrder)
        }
        // console.log(arr);
        return arr
    }

    function stringSort(array, tempOrder) {
        let arr = []
        if (newData) {
            if (tempOrder) {
                // console.log("descending sorting");
                arr = array.sort((b, a) =>
                    a?.get('name').localeCompare(b.get('name'))
                )
            } else {
                // console.log("ascending");
                arr = array.sort((a, b) =>
                    a.get('name').localeCompare(b.get('name'))
                )
            }
        }
        return arr
    }

    function numSort(array, tempOrder) {
        let arr = []
        if (newData) {
            if (tempOrder) {
                arr = array.sort((a, b) => a.get('balance') - b.get('balance'))
            } else {
                arr = array.sort((b, a) => a.get('balance') - b.get('balance'))
            }
        }
        return arr
    }

    const onSearch = (e) => {
        e.preventDefault()
        setSearchInput(() => e.target.value)
    }

    const searchFiltering = () => {
        const tempArray = newData? newData.filter((account, index) => {
            return (
                account
                    .get('name')
                    .toLowerCase()
                    .includes(searchInput.toLowerCase()) ||
                account.get('phone')?.includes(searchInput)
            )
        }) : [];
        // console.log(tempArray);
        setFiltered(() => sortFunction(tempArray))
    }

    const setforedit = (acc) => {
        const newData = { editAc: acc }
        dispatch(setForUpdate(newData))
    }

    const confirmit = (acc) => {
        setClosingOf(acc);
    }

    const updateAccountsState = (newAc) => {
        const arrayIndex = newData.findIndex((obj) => obj.id == newAc.id)
        newData[arrayIndex] = newAc
        dispatch(getReduxAccounts(newData))
    }

    const closeit = () => {
        if (closingOf.get('balance') == 0) {
            api.closeAccount(closingOf.id).then((result) => {
                updateAccountsState(result)
            })
        } else {
            alert("Can't delete active account")
        }
    }

    const confirmDel = (acc) => {
        const toDel = { delAcc: acc };
        dispatch(setForUpdate(toDel));
    }

    const deleteit = () => {
        if (deletionOf.get('cancelled') == true) {
            api.deleteAccount(deletionOf.id).then((result) => {
                updateAccountsState(result)
            })
        } else {
            alert('Close this account first, then try again.')
        }
    }

    const changeHideClosed = (e) => {
        e.stopPropagation()
        e.preventDefault()
        const temp = hideClosed
        setHideClosed(!temp)
        setUserKhatabookFilter(!temp)
    }

    const clearNewAcc = () => {
        document.getElementById('account').value = ''
        document.getElementById('phone').value = ''
    }

    const reOpen = (data) => {
        api.reopenAccount(data.id).then((result) => {
            updateAccountsState(result)
        })
    }

    const changeSortByName = (e) => {
        // e.stopPropagation();
        e.preventDefault()
        if (sortBy === 'name') {
            setSortOrder((order) => !order)
        } else {
            setSortBy('name')
        }
    }

    const changeSortByBalance = (e) => {
        // e.stopPropagation();
        e.preventDefault()
        if (sortBy === 'balance') {
            setSortOrder((order) => !order)
        } else {
            setSortBy('balance')
        }
    }

    const filterToggler = (e) => {
        e.preventDefault()
        const temp = hideClosed
        setHideClosed(() => !temp)
        setUserKhatabookFilter(!temp)
    }

    return (
        <>
            <div className="border-light border-bottom">
                <div id="Search" style={{ gap: '0', padding: '0rem 5px 0rem 5px' }} className='justify-content-around d-flex align-items-baseline pb-1'>
                    {/* search bar */}
                    <div class="input-group my-2 border border-dark rounded-2">
                        <span className="input-group-text btn border border-dark border-start-0 border-top-0 border-bottom-0" id="inputgroupicon">
                            <i className='bi bi-search'></i>
                        </span>
                        <input type="text" class="form-control py-0 col-8 accountsearch btn text-start me-2" placeholder="Search accounts" aria-label="Search" aria-describedby="inputgroupicon" onChange={onSearch} />
                    </div>
                    {/* <div className=''>
                        <input
                            id="searchhomeinput"
                            type="text"
                            aria-label="Search"
                            className="form-control mt-2 py-0 col-9 accountsearch btn border border-3 border-danger-subtle text-start"
                            placeholder="Search accounts"
                            aria-describedby="addon1"
                            onChange={onSearch}
                        />
                    </div> */}
                    {/* filter and sort */}
                    <span
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'baseline',
                            gap: '1rem',
                            width: 'fit-content'
                        }}
                        className="w-25"
                    >
                        <i
                            className={
                                hideClosed
                                    ? 'fs-4 bi bi-filter-circle-fill text-danger'
                                    : 'fw-bold fs-4 bi bi-filter'
                            }
                            role="button"
                            onClick={filterToggler}
                            title={
                                hideClosed
                                    ? 'Show Closed Accounts'
                                    : 'Hide Closed Account'
                            }
                        ></i>
                        <i
                            className="fw-bold my-auto bi bi-arrow-down-up dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        ></i>
                        <ul className="dropdown-menu">
                            <li
                                className="dropdown-item d-flex"
                                onClick={changeSortByName}
                            >
                                <span className="me-auto">Name</span>
                                <span
                                    className={
                                        sortBy === 'name' ? '' : 'invisible'
                                    }
                                >
                                    <i
                                        className={
                                            sortOrder
                                                ? 'bi bi-sort-alpha-down'
                                                : 'bi bi-sort-alpha-up'
                                        }
                                    ></i>
                                </span>
                            </li>
                            <li
                                className="dropdown-item d-flex"
                                onClick={changeSortByBalance}
                            >
                                <span className="me-auto">Balance</span>
                                <span
                                    className={
                                        sortBy === 'balance'
                                            ? ''
                                            : 'invisible'
                                    }
                                >
                                    <i
                                        className={
                                            sortOrder
                                                ? 'bi bi-sort-numeric-up-alt'
                                                : 'bi bi-sort-numeric-down'
                                        }
                                    ></i>
                                </span>
                            </li>
                        </ul>
                    </span>
                </div>

                <div>
                    <div className="d-flex h-100">
                        {loading && !newData ? (
                            <div className="spinner-border text-danger mx-auto mt-3 my-auto"></div>
                        ) : filtered.length == 0 ? (
                            <div className="text-center my-3 fw-bold mx-auto py-2">
                                No Accounts found
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <ListGroup variant="flush" id="khatabookAccounts" className="mt-2 khatabook-scroll scrollbar-design">
                        {filtered.map((account, i) =>
                            hideClosed && account.get('cancelled') ? null : (
                                <Account
                                    key={i}
                                    data={account}
                                    onClose={confirmit}
                                    onReopen={reOpen}
                                    onDel={confirmDel}
                                    onEdit={setforedit}
                                />
                            )
                        )}
                    </ListGroup>
                </div>
                <div className="row new-account-btn">
                    <div className="col-md-12 col-12" title="Add Account">
                        <button
                            className="second-step btn btn-danger rounded-circle p-3 border-width-med"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#addContactDrawer"
                            aria-controls="addContactDrawer"
                            id="addaccbtn"
                            onClick={(e) => {
                                clearNewAcc(e)
                                goToNextStep()
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faUserPlus}
                                size="1x"
                                className="my-auto fs-4"
                            />
                        </button>
                    </div>
                </div>
            </div>

            {/*  Modal  */}
            <div
                className="modal fade"
                id="khataBookModal"
                tabIndex="-1"
                aria-labelledby="khataBookModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h1
                                className="modal-title fs-5"
                                id="khataBookModalLabel"
                            >
                                Are you sure, you want to{' '}
                                {'Close'} ?
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-footer border-0">
                            <button
                                type="button"
                                className="btn btn-outline-dark"
                                data-bs-dismiss="modal"
                            >
                                {' '}
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="btn btn-outline-danger"
                                data-bs-dismiss="modal"
                                onClick={closeit}
                            >
                                <i className="bi bi-trash-fill"></i>
                                {' Close'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default index
