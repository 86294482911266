import { useState, useEffect, useRef } from 'react'
import { ListGroup, Col, Dropdown } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/free-solid-svg-icons'
import { api, auth } from 'services'
import Txn from './Txn'
import { ConfirmDelete } from 'components'
import OpenBalance from 'components/OpenBalance'
import CloseBalance from 'components/CloseBalance'
import MonthView from './MonthView'
import Reminder from 'components/Reminder'
import ReminderAlert from 'components/ReminderAlert'
import { useDispatch, useSelector } from 'react-redux'
import { setForUpdate } from 'redux/actions/updateAction'
import { getReduxAccounts } from 'redux/actions/accountActions'
import RecursivetxnsView from 'views/Forms/Recursive/RecursivetxnsView'
import { useOnboardingStore } from 'store/onboarding-store'
import useDevice from 'hooks/useDevice'

const accounting = require('accounting')

const Khata = () => {
    const { id } = useParams()
    const device = useDevice()
    const headerRef = useRef(null)
    const navBarRef = useRef(document.getElementById('main-nav'))
    const bottomNav = useRef(null)
    const [headerHeight, setHeaderHeight] = useState(225)

    const [txns, setTxns] = useState([])
    const [account, setAccount] = useState(null)
    const [filterDate, setFilterDate] = useState(new Date())
    const [openBalance, setOpenBalance] = useState(0)
    const [closeBalance, setCloseBalance] = useState(0)
    const [balance, setBalance] = useState(0)
    const [loading, setLoading] = useState(true)
    const [reportLoading, setReportLoading] = useState(false)
    const [showReminder, setShowReminder] = useState(false)
    const allaccountsData = useSelector((state) => state.accounts.allAccounts)
    const copiedAccounts = allaccountsData ? [...allaccountsData] : null
    const allTransction = useSelector(
        (state) => state.transction.allTransctions
    )
    const copiedTransactions = allTransction ? [...allTransction] : null
    const loadingDialog = document.getElementById('Loadingdialog')
    const dispatch = useDispatch()
    const curruser = auth.fetchUser()
    const currDate = new Date()
    const pseudoDate1 = new Date(
        filterDate.getFullYear(),
        filterDate.getMonth(),
        0,
        23,
        59,
        59,
        999
    )
    const pseudoDate2 = new Date(
        filterDate.getFullYear(),
        filterDate.getMonth() + 1
    )
    const goToNextStep = useOnboardingStore((state) => state.goToNextStep)

    const overDue = currDate >= account?.get('date') && account?.get('enabled')

    useEffect(() => {
        getOpenKhata()
    }, [allaccountsData, id])

    useEffect(() => {
        if (!navBarRef.current) {
            navBarRef.current = document.getElementById('main-nav')
        }

        // console.log('headerRef.current', headerRef.current, navBarRef.current)
        if (headerRef.current && navBarRef.current && bottomNav.current) {
            setHeaderHeight(
                headerRef.current.clientHeight +
                    navBarRef.current.clientHeight +
                    bottomNav.current.clientHeight
            )
        }
    }, [
        headerRef.current?.clientHeight,
        navBarRef.current?.clientHeight,
        bottomNav.current?.clientHeight
    ])

    useEffect(() => {
        if (overDue) {
            setShowReminder((prev) => true)
        } else {
            setShowReminder((prev) => false)
        }
    }, [overDue])

    useEffect(() => {
        if (!loading) {
            loadingDialog?.close()
        }
        if (!account) return
        let opensum = 0
        let closesum = 0
        let overallBalance = 0
        let trans1 = []

        const userObjectId = account.id
        copiedTransactions?.map((transaction) => {
            const Date = transaction.get('date')
            if (
                transaction.get('accountId')?.id === userObjectId &&
                transaction.get('cancelled') === false
            ) {
                overallBalance += transaction.get('amount')
            }
            if (
                transaction.get('accountId')?.id === userObjectId &&
                Date > pseudoDate1 &&
                Date < pseudoDate2
            ) {
                trans1.push(transaction)
            }
            if (
                transaction.get('accountId')?.id === userObjectId &&
                Date < pseudoDate1 &&
                transaction.get('cancelled') === false
            ) {
                opensum += transaction.get('amount')
            }
            if (
                transaction.get('accountId')?.id === userObjectId &&
                Date < pseudoDate2 &&
                transaction.get('cancelled') === false
            ) {
                closesum += transaction.get('amount')
            }
        })
        setTxns(trans1)
        setOpenBalance(opensum)
        setCloseBalance(closesum)
        setBalance(overallBalance)
        checkBalance(overallBalance)
        // allExec()
        setLoading(() => false)
    }, [filterDate, account])

    const getOpenKhata = () => {
        if (copiedAccounts) {
            return copiedAccounts.map((account) => {
                if (account.id === id) {
                    setLoading(() => false)
                    setAccount(account)
                    return account
                }
            })
        }
    }

    const checkBalance = (calculatedBalance) => {
        const prevBalance = account.get('balance')
        if (prevBalance !== calculatedBalance) {
            const errorAmount = calculatedBalance - prevBalance
            api.updateBalance(account.id, errorAmount).then((newAc) => {
                updateAccountState(newAc)
            })
        }
    }

  const updateAccountState = (newAc) => {
    const arrayIndex = copiedAccounts.findIndex(obj => obj.id == newAc.id);
    copiedAccounts[arrayIndex] = newAc;
    dispatch(getReduxAccounts(copiedAccounts));
  }

  const noAccountView = () => {
    return (
      <div className="background pt-5">
        <h3 className="text-center">No Account found !</h3>
      </div>
    );
  };

  const handleDownload = (value) => {
    const endMonth = new Date();
    let startMonth = new Date();
    startMonth.setHours(0,0,0,0);
    if (value === 1) {
      startMonth.setDate(1);
      setReportLoading(true);
    } else if (value === "lifetime") {
      setReportLoading(true);
      startMonth = "lifetime";
    } else {
      startMonth.setMonth(endMonth.getMonth() - value);
      setReportLoading(true);
    }
    // console.log(startMonth, endMonth);
    api.downloadMonthlyReport(id, startMonth, endMonth).then((res) => {
        setReportLoading(false);
      if (res) {
        // null
      } else {
        alert("Something went wrong! Could not download report. Please try again later.")
      }
    });
  };

    const reportsView = () => {
        return (
            <Dropdown>
                <Dropdown.Toggle
                    className="btn btn-danger btn-sm"
                    id="dropdown-basic"
                    title="Reports"
                >
                    <div className="d-flex">
                        {reportLoading ? (
                            <span className="mt-1 spinner-border text-white"></span>
                        ) : (
                            <i
                                className="bi bi-file-earmark-arrow-down"
                                style={{ fontSize: '2rem', color: 'white' }}
                            ></i>
                        )}
                    </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleDownload(1)}>
                        Current Month
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDownload(3)}>
                        3 Month
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDownload(6)}>
                        6 Month
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDownload(12)}>
                        1 Year
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDownload('lifetime')}>
                        Life Time
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    const header = () => {
        return (
            <div
                className="bg-danger text-white py-2"
                ref={headerRef}
                style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <span>
                    <h3 className="text-left d-flex">
                        <i
                            className="bi bi-person-circle mx-3"
                            style={{ fontSize: '2rem', color: 'white' }}
                        ></i>
                        <div className="my-auto me-md-auto">
                            {account
                                ? account.get('name').substring(0, 30)
                                : ''}
                        </div>
                    </h3>
                </span>
                <span>
                    <MonthView onDateChange={handleNewDate} display={true} />
                </span>
            </div>
        )
    }

    const loadingView = () => {
        loadingDialog?.showModal()
        return (
            <div className="background pt-5">
                <h3 className="text-center"></h3>
            </div>
        )
    }

    const edittxn = (data) => {
        const updateData = { editTxn: data }
        dispatch(setForUpdate(updateData))
    }

    const confirmit = (txn) => {
        const toDel = { delTxn: txn }
        dispatch(setForUpdate(toDel))
        // setDeletionOf(txn);
    }

    const handleNewDate = (year, month) => {
        let theNewDate = new Date(year, month)
        setFilterDate(theNewDate)
    }

    const txnViewHeight =
        device === 'desktop' ? `calc(100vh - ${headerHeight}px)` : 'auto'
    // console.log('txnViewHeight', txnViewHeight)
    const TxnsView = () => {
        return (
            <div
                style={{
                    height: txnViewHeight,
                    overflowY: 'scroll'
                }} className='scrollbar-design'
            >
                <div
                    className={`background h-auto mb-auto pb-5 overflow-hidden ${
                        showReminder ? 'opacity-25' : ''
                    }`}
                >
                    <div className="row">
                        <Col md={6} align="center" className="text-success">
                            <i className="bi bi-arrow-down-circle-fill fs-3"></i>
                            <span className="fs-3 text-uppercase px-3">
                                <b>In</b>
                            </span>
                        </Col>
                        <Col md={6} align="center" className="text-danger">
                            <i className="bi bi-arrow-up-circle-fill fs-3"></i>
                            <span className="fs-3 text-uppercase px-3">
                                <b>Out</b>
                            </span>
                        </Col>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <OpenBalance sum={openBalance} />
                            <ListGroup variant="flush">
                                {txns
                                    ?.filter((txn) => txn.get('amount') > 0)
                                    ?.map((txn, i) => {
                                        if (!txn.cancelled) {
                                            return (
                                                <Txn
                                                    data={txn}
                                                    key={i}
                                                    onEdit={edittxn}
                                                    onDel={confirmit}
                                                />
                                            )
                                        }
                                        return null
                                    })}
                            </ListGroup>
                        </div>
                        <div className="col-md-6">
                            <ListGroup variant="flush">
                                {txns
                                    ?.filter((txn) => txn.get('amount') < 0)
                                    ?.map((txn, i) => {
                                        if (!txn.cancelled) {
                                            return (
                                                <Txn
                                                    data={txn}
                                                    key={i}
                                                    onEdit={edittxn}
                                                    onDel={confirmit}
                                                />
                                            )
                                        }
                                    })}
                                <CloseBalance sum={closeBalance} />
                            </ListGroup>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const remind = () => {
        // alert('reminder');
        const num = account.get('phone')
        const name = curruser.get('name')
        if (account.get('balance') < 0) {
            const bal = -account.get('balance')
            var line1 = 'Payment Reminder : '
            var line2 = `Balance *₹${bal}* due to *${name}*`
        } else {
            const bal = account.get('balance')
            var line1 = 'Payment details : '
            var line2 = `Balance *₹${bal}* advance to *${name}*`
        }
        const link = 'Ledger : https://web.bahikhata.org/p/' + id
        const line3 = `Sent via BahiKhata App`
        window.open(
            `https://wa.me/${num}?text=${line1}%0a${line2}%0a${link}%0a%0a${line3}`,
            '_blank'
        )
    }

    return (
        <>
            <div className="container-fluid px-0">
                {header()}

                {account
                    ? loading
                        ? loadingView()
                        : TxnsView()
                    : loading
                    ? loadingView()
                    : noAccountView()}

                <div
                    className={
                        curruser
                            ? 'row position-fixed bottom-0 end-0 my-5 py-5 px-4 w-auto'
                            : 'd-none'
                    }
                >
                    <div className="sixth-step col-md-12">
                        <button
                            className="btn btn-danger rounded-pill px-4 border-width-med"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#addTxnDrawer"
                            aria-controls="addTxnDrawer"
                            onClick={() => goToNextStep()}
                        >
                            <FontAwesomeIcon
                                icon={faPencil}
                                size="1x"
                                className="my-auto fs-4"
                            />
                            <span className="my-auto fs-3 mx-2 fonts-for-buttons-label">
                                <strong>New Entry</strong>
                            </span>
                        </button>
                    </div>
                </div>
                <div>
                    <ReminderAlert
                        curruser={curruser}
                        account={account}
                        id={id}
                        setShowReminder={setShowReminder}
                        showReminder={showReminder}
                        updateFunction={updateAccountState}
                    />
                </div>
                <div
                    className="sticky-bottom bg-danger py-3 px-3 text-white d-flex"
                    ref={bottomNav}
                >
                    <h3
                        className="my-auto text-left text-md-left"
                        title="Account Balance"
                    >
                        Balance :{' '}
                        {account
                            ? accounting.formatMoney(Math.abs(balance), '₹', 0)
                            : ''}
                    </h3>
                    {account ? (
                        <div className="col-4 d-flex ms-auto" align="right">
                            <div className="col-3 d-flex" align="right">
                                <Reminder
                                    account={account}
                                    id={id}
                                    currentSetDate={account.get('date')}
                                />
                            </div>
                            <div className="col-3 d-flex" align="right">
                                <RecursivetxnsView
                                    openKhata={account}
                                    getOpenKhata={getOpenKhata}
                                />
                            </div>
                            <div className="col-3 d-flex" align="right">
                                {reportsView()}
                            </div>
                            <div className="col-3 d-flex" align="right">
                                <button
                                    className="btn btn-sm btn-danger rounded"
                                    title="Remind"
                                    onClick={remind}
                                >
                                    <i
                                        className="bi bi-whatsapp"
                                        style={{
                                            fontSize: '2rem',
                                            color: 'white'
                                        }}
                                    ></i>
                                </button>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
            <ConfirmDelete />
        </>
    )
}

export default Khata
