import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "services";

function Settings() {
  const back = () => {
    navigate(-1);
  }

  return (
    <div className="height-full">
      <nav className="row bg-danger py-2 justify-content-around h4 text-light">
        <div className="col my-auto fs-1 ms-2">
          <a role="button" className="btn btn-light my-2 ms-auto me-2" onClick={back} title="Back">
            <i className="bi bi-box-arrow-in-left"></i>
          </a>
        </div>
        <div className="col text-center my-auto h1">Settings</div>
        <div className="col d-flex">
        </div>
      </nav>
      <div className="ms-8 h-100">
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          <h1 className="text-danger m-auto">Comming Soon...</h1>
        </div>
      </div>
    </div>
  )
}

export default Settings;
