import React, { useEffect, useState } from "react";
import { Button, Container, Row, Col, Image, ListGroup } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { api } from "services";
import ModalImage from "react-modal-image";

function Txn(props) {
  const { data } = props;
  let navigate = useNavigate();

  const openKhata = () => {
    navigate("/khata/" + data.get("accountId").id);
  }

  function deltransaction(txn) {
    if (confirm("Delete?")) {
      api.deleteTransaction(txn.id).then(() => {
        if (txn.get("accountId").id) {
          var acId = txn.get("accountId").id;
          api.updateBalance(acId, -txn.get("amount"));
        }
      }).catch((err) => {
        console.log(err);
      });
    }
  }

  const cancelledView = () => {
    return (
      <div className="row align-baseline">
        <div className="col-3" align="right">
          <i className="text-decoration-line-through text-muted">
            {Math.abs(data.get("amount")).toLocaleString('en-IN', {
              maximumFractionDigits: 0,
              style: 'currency',
              currency: 'INR'
            })}
          </i>
        </div>
        <div className="col-9">
          {
            data.get("accountId") ? (
              <div className="row">
                <div className="col text-start">
                  <a className="text-decoration-line-through text-muted pe-none">
                    {data.get("accountId").get("name")}
                  </a>
                </div>
              </div>
            ) : null
          }
          <div className="row text-start">
            <div className="col text-decoration-line-through text-muted">
              {data.get("detail")}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const normalView = () => {
    return (
      <div className="row align-baseline">
        <div className="col-3" align="right">
          <i className="text-decoration-none text-color-format">
            {Math.abs(data.get("amount")).toLocaleString('en-IN', {
              maximumFractionDigits: 0,
              style: 'currency',
              currency: 'INR'
            })}
          </i>
        </div>
        <div className="col-8">
              {
                data.get("accountId") ? (
                  <div className="row">
                    <div className="col text-start">
                      <a href="#" className="text-decoration-none pe-auto fw-bold fst-italic text-dark"
                        onClick={openKhata}>{data.get("accountId").get("name")}</a>
                    </div>      
                  </div>
                ) : null    
              }
          <div className="row text-start">
            <div className="col">
              {data.get("detail")}
            </div>
          </div>
          <div className="row text-start">
            {data.get("attachment")? 
              <ModalImage
                small={data.get("attachment")}
                large={data.get("attachment")}
                hideDownload={true}
                hideZoom={false}
                showRotate={true}
                className="col-4 m-2 px-0 img-fluid rounded-2 border border-2 border-danger"
                />
            :null}
          </div>
        </div>
        <div className="col-1">
          <a className="text-black btn bg-not-transparent-on-hover w-auto" type="button" data-bs-toggle="dropdown" aria-expanded="false" title="More options" align="right">
            <span className="bi bi-three-dots-vertical"></span>
          </a>
          <ul className="dropdown-menu row w-auto">
            <li className="col-12 py-1">
              <button className="w-100 btn btn-outline-dark rounded" onClick={() => props.onEdit(data)} data-bs-toggle="offcanvas" data-bs-target="#editTxnDrawer" aria-controls="editTxnDrawer">
                <span className="bi bi-pencil-fill"> Edit</span>
              </button>
            </li>
            <li className="col-12 py-1">
                  <button className="w-100 btn btn-danger rounded" onClick={() => props.onDel(data)} data-bs-toggle="modal" data-bs-target="#delModal">
                    <span className="bi bi-trash"> Delete</span>
                  </button>
              </li>
          </ul>
        </div>
      </div>
    );
  }

  return (
    <ListGroup.Item className="bg-transparent">
      { data.get("cancelled") ? cancelledView() : normalView() }
    </ListGroup.Item>
  )
}

export default Txn;
