import React, { useEffect, useState } from "react";
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'
import { useDispatch, useSelector } from "react-redux";
import { getReduxAccounts } from "redux/actions/accountActions";
import { api } from 'services';
// import { useNavigate } from "react-router-dom";

function EditAccount(props) {
  const [invalid, setInValid] = useState(true);
  const allaccountsData = useSelector((state) => state.accounts.allAccounts);
  const newAccounts = allaccountsData ? [...allaccountsData] : null;
  const updateData = useSelector((state) => state.updater.allUpdates);
  const forEdit = updateData ? updateData.editAc : null;
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    name: '',
    phone: '',
    loading: false,
    errormsg: '',
    successmsg: ''
  });

  useEffect(() => {
    let temp = isValidPhoneNumber("+91" + values.phone) || values.phone == "";
    setInValid(!temp)
  }, [values.phone])

  useEffect(() => {
    if (forEdit?.id) {
      setValues({
        name: forEdit.get("name"),
        phone: forEdit.get("phone"),
        errormsg: '',
        successmsg: ''
      })
    }
  }, [forEdit])

  const success = (message) => {
    setValues({
      ...values,
      successmsg: message,
      errormsg: '',
      loading: false
    })

    setTimeout(() => {
      setValues({
        ...values,
        successmsg: ""
      })
    }, 5000);
  }

  const failure = (message) => {
    setValues({
      ...values,
      successmsg: '',
      errormsg: message,
      loading: false
    })

    setTimeout(() => {
      setValues({
        ...values,
        errormsg: ""
      })
    }, 5000);
  }

  const superSuccess = () => {
    setTimeout(() => {
      hideOffcanvas();
      setValues({
        name: '',
        phone: '',
        errormsg: '',
        successmsg: '',
        loading: false
      })
    }, 750);
  }


  const add = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      loading: true
    })
    api.getAccountByName(values.name).then((account) => {
      if (account) {
        var id = forEdit.id;
        if (account.id == id) {
          if (account.get("phone") == values.phone) {
            success('No details Updated!');
            superSuccess();
          } else {
            // error and success messages are handled in setAccount() func...
            setAccount();
          }
        } else {
          failure('Account with this name already exists');
        }
      } else {
        setAccount();
      }
    }).catch((error) => {
      console.error(error.message);
    })
  }
  const setAccount = () => {
    var id = forEdit.id;
    api.updateAccount(id, values.name, values.phone).then((result) => {
      updateAccountsState(result);
      setValues({
        name: '',
        phone: '',
        errormsg: '',
        successmsg: 'Account Updated Successfully!'
      });
      superSuccess();
      // props.onTriggerAccounts();
    }).catch((error) => {
      // alert(error.message);
      failure(error.message)
    });
  };

  const updateAccountsState = (newAc) => {
    const arrayIndex = newAccounts.findIndex(obj => obj.id == newAc.id);
    // console.log(arrayIndex);
    newAccounts[arrayIndex] = newAc;
    dispatch(getReduxAccounts(newAccounts));
  }

  const nameChangeHandler = (e) => {
    const target = e.target;
    setValues((values) => ({
      ...values,
      name: target.value,
      errormsg: '',
      successmsg: ''
    }));
  }

  const phoneChangeHandler = (e) => {
    const target = e.target;
    setValues((values) => ({
      ...values,
      phone: target.value,
      errormsg: '',
      successmsg: ''
    }));
  }

  const hideOffcanvas = () => {
    const myOffCanvas = document.getElementById("editAccDrawer");
    const openedCanvas = bootstrap.Offcanvas.getInstance(myOffCanvas);
    openedCanvas.hide();
  }

  return (
    <div className="offcanvas offcanvas-start text-danger" tabIndex="-1" id="editAccDrawer" aria-labelledby="offcanvasExampleLabel">
      <div className="offcanvas-header bg-danger text-light">
        <h3 className="offcanvas-title">Edit Account</h3>
        <button type="button" className="btn-close text-reset bg-light" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <form onSubmit={add} id="editaccountform">
                <div className="py-3">
                  <input type="text" className={values.name ? "form-control is-valid" : "form-control"} id="editaccname" placeholder="Account Name" value={values.name} onChange={nameChangeHandler} required />
                </div>
                <div className="py-3">
                  <input type="tel" className={invalid ? "form-control" : values.phone ? "form-control is-valid" : "form-control"} id="editaccphone" placeholder="Phone no. (Optional)" value={values.phone} onChange={phoneChangeHandler} />
                </div>
                {values.loading ? (<div className="form-group text-start">
                  <div className="form-check-label alert alert-warning text-capitalized" role="alert">
                    <span className="spinner-border spinner-border-sm"></span> Loading...
                  </div>
                </div>) : <></>}
                {values.errormsg ? (<div className="form-group text-start animate__animated animate__pulse">
                  <div className="form-check-label alert alert-danger text-capitalized" id="editacerror" role="alert">
                    <i className="bi bi-exclamation-circle-fill"></i> {values.errormsg}
                  </div>
                </div>) : <div className="py-2"></div>}
                {values.successmsg ? (<div className="form-group text-start">
                  <div className="form-check-label alert alert-success text-capitalized" id="editacsuccess" role="alert">
                    <i className="bi bi-check-circle-fill"></i> {values.successmsg}
                  </div>
                </div>) : <div className="py-2"></div>}
                <div className="row mb-3">
                  <div className="col-md-12 d-grid">
                    <button type="submit" className="btn btn-outline-danger block py-3" disabled={values.name == "" || invalid}>
                      <b className="fs-5">Update</b>
                    </button>
                    {/* <button className="btn btn-secondary block my-3" onClick={testfunction}>Hide</button> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default EditAccount;
